import axios from 'axios'
import {error} from '../../utils/error'

const TOKEN_KEY = 'jwt-token';

export default {
    namespaced: true,
    state() {
        return {
            token: localStorage.getItem(TOKEN_KEY)
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem(TOKEN_KEY, token)
        },
        logout(state) {
            state.token = null
            localStorage.removeItem(TOKEN_KEY)
        }
    },
    actions: {
        async login({ commit, dispatch }, payload) {
            try {
                const url = process.env.VUE_APP_API_URL + '/api/login'
                const {data} = await axios.post(url, payload)
                commit('setToken', data.access_token)
                commit('clearMessage', null, {root: true})
            } catch (e) {
                dispatch('setMessage', {
                    value: error(e.response.data.message),
                    type: 'danger'
                }, {root: true})
                console.log(error(e.response.data.message));
                throw new Error()
            }
        }
    },
    getters: {
        token(state) {
            return state.token
        },
        isAuthenticated(_, getters) {
            return !!getters.token
        }
    }
}
