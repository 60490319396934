<template>
  <nav class="navbar">
    <h3>CRM "Рубеж Техно"</h3>

    <i @click="visibleMobileMenu = !visibleMobileMenu" class="mobileMenuIcon pi pi-bars"></i>

    <div class="navbar-mobile-menu" v-if="visibleMobileMenu">
      <ul style="margin-top: 16px" class="navbar-mobile-menu-list">
      <li v-for="link in menuItems" :key="link.name">
        <router-link @click="visibleMobileMenu = false" class="mobile-menu-link" :to="{ name: link.route }">{{ link.name }}</router-link>
      </li>
    </ul>
    </div>

    <ul class="desktopNavBarMenu navbar-menu" style="margin-top: 16px">
      <li v-for="link in menuItems" :key="link.name">
        <router-link :to="{ name: link.route }">{{ link.name }}</router-link>
      </li>
      <li>
        <a href="#" @click.prevent="logout">Выход</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();

    return {
      logout: () => {
        store.commit("auth/logout");
        router.push("/auth");
      },
    };
  },
  data() {
    return {
      visibleMobileMenu: false,
      menuItems: [
        {
          route: 'Trip',
          name: 'Командировки'
        },
        {
          route: 'User',
          name: 'Сотрудники'
        },
        {
          route: 'Task',
          name: 'Задачи'
        },
        {
          route: 'Vacation',
          name: 'Отпуска'
        },
        {
          route: 'SickLeave',
          name: 'Больничные'
        },
        {
          route: 'GantIndex',
          name: 'Гант'
        }
      ]
    };
  },
  mounted() {
    this.visibleMobileMenu = false;
  }
};
</script>

<style scoped>
</style>