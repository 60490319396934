import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    redirect: to => {
      return { path: '/trip' }
    },
  },
  {
    path: '/trip',
    name: 'Trip',
    component: () => import('../modules/trip/views/Index.vue'),
    meta: { 
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/trip/:id',
    name: 'TripShow',
    component: () => import('../modules/trip/views/Show.vue'),
    meta: { 
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/trip/create',
    name: 'TripCreate',
    component: () => import('../modules/trip/views/Create.vue'),
    meta: { 
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/trip/edit/:id',
    name: 'TripEdit',
    component: () => import('../modules/trip/views/Update.vue'),
    meta: { 
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/task',
    name: 'Task',
    component: () => import('@/modules/task/views/Index.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/task/:id',
    name: 'TaskShow',
    component: () => import('@/modules/task/views/Show.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/task/edit/:id',
    name: 'TaskEdit',
    component: () => import('@/modules/task/views/Update.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/task/create',
    name: 'TaskCreate',
    component: () => import('@/modules/task/views/Create.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/modules/user/views/Index.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/user/:id',
    name: 'UserShow',
    component: () => import('@/modules/user/views/Show.vue'),
    meta: { 
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/user/edit/:id',
    name: 'UserEdit',
    component: () => import('@/modules/user/views/Update.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/vacation',
    name: 'Vacation',
    component: () => import('@/modules/vacation/views/Index.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/vacation/:id',
    name: 'VacationShow',
    component: () => import('@/modules/vacation/views/Show.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/vacation/create',
    name: 'VacationCreate',
    component: () => import('@/modules/vacation/views/Create.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/vacation/edit/:id',
    name: 'VacationEdit',
    component: () => import('@/modules/vacation/views/Update.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/sick-leave',
    name: 'SickLeave',
    component: () => import('@/modules/sickLeave/views/Index.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/sick-leave/:id',
    name: 'SickLeaveShow',
    component: () => import('@/modules/sickLeave/views/Show.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/sick-leave/create',
    name: 'SickLeaveCreate',
    component: () => import('@/modules/sickLeave/views/Create.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/sick-leave/edit/:id',
    name: 'SickLeaveEdit',
    component: () => import('@/modules/sickLeave/views/Update.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/gant',
    name: 'GantIndex',
    component: () => import('@/modules/gant/views/Index.vue'),
    meta: {
      layout: 'main',
      auth: true
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta: {
      layout: 'auth',
      auth: false
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiredAuth = to.meta.auth

  if (requiredAuth && store.getters['auth/isAuthenticated']) {
    next()
  } else if (requiredAuth && !store.getters['auth/isAuthenticated']) {
    next('/auth?message=auth')
  } else {
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   to.meta.auth = (to.meta.auth === undefined) ? true : to.meta.auth
//   to.meta.layout = (to.meta.layout === undefined) ? 'main' : to.meta.layout
// });

export default router
